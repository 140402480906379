import { type RegisteredKeyEventResponse } from 'types/registered_key_event';
import { getRequest } from 'utils/rest-api-util';
import { StringUtil } from 'utils/string-util';

export const getRegisteredKeyEvents = async (
  analyticId: string,
): Promise<RegisteredKeyEventResponse> => {
  const requestPath = `/api/v1/analytics/${analyticId}/registered_key_events`;

  const response = await getRequest(requestPath);

  return StringUtil.snakeObjectToCamelObject(
    await response.json(),
  ) as RegisteredKeyEventResponse;
};
